import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { IUserFeedback } from '../../../../dataObjects/models/userFeedback';
import { UserFeedbackCardGridViewItem } from './UserFeedbackCardGridViewItem';
import { IUser } from '../../../../dataObjects/models/users/User';
import { enumSharingPermission } from '../../../../dataObjects/enums';


export interface IUserFeedbackCardGridViewProps extends PropsWithChildren<unknown> {
  userFeedback: Array<IUserFeedback>;
  currentUser: IUser;
  onDelete: (userFeedback: IUserFeedback) => void;
}


export const UserFeedbackCardGridView: React.FC<IUserFeedbackCardGridViewProps> = (props: IUserFeedbackCardGridViewProps) => {
  UserFeedbackCardGridView.displayName = 'UserFeedback Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render UserFeedbackList`);

  const { userFeedback, currentUser, onDelete } = props;

  const userPermission : enumSharingPermission = currentUser.sa ? enumSharingPermission.Admin : enumSharingPermission.None;

  return (
    <>
      <Grid container spacing={2} >
        {
          // only display userFeedback if there is a currentUser
          currentUser &&  
          userFeedback.map(userFeedback =>
            <Grid item key={userFeedback.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <UserFeedbackCardGridViewItem userFeedback={userFeedback} userPermission={userPermission} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}