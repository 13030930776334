import React, { PropsWithChildren } from 'react';
import { Box, BoxProps, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { listViewItemStyles } from '../../../styles';
import { IUserFeedback } from '../../../../dataObjects/models/userFeedback';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { styled } from '@mui/styles';


// a styled Box (equivalent to a <div>), providing an area for the overall view of the application
const StyledDivForTypographySegments = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}));

export interface IUserFeedbackListViewItemProps extends PropsWithChildren<unknown> {
  userFeedback: IUserFeedback;
  userPermission: enumSharingPermission;
  onDelete: (userFeedback: IUserFeedback) => void;
}

export const UserFeedbackListViewItem: React.FC<IUserFeedbackListViewItemProps> = (props: IUserFeedbackListViewItemProps) => {
  UserFeedbackListViewItem.displayName = 'UserFeedback List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render UserFeedbackListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { userFeedback, userPermission, onDelete } = props;
  const { id, userId, submissionTimestamp, submitterName, submitterMobilePhone, userFeedbackType, title, description, response } = userFeedback;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  const userHasPermission: boolean = userPermission === enumSharingPermission.Admin;

  return (
    <>
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Title & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* Name */}
          <Grid item container xs={9} sm={10} >
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={3} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => id && navigate(`/userFeedback/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!userHasPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    disabled={!userHasPermission}
                    onClick={() => userFeedback && onDelete(userFeedback)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Submission Date  */}
        <Grid item container xs={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Type:'}&nbsp;
            </Typography>
            <Typography className={classes.description} variant="body1">
              {userFeedbackType}
            </Typography>
          </StyledDivForTypographySegments>
          </Grid>
          <Grid item container xs={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Submitted:'}&nbsp;
            </Typography>
            <Typography className={classes.generalTimestamp} variant="body1">
              {
                moment(submissionTimestamp).calendar()
              }
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>
        {/* Data Row 3: Description  */}
        <Grid item container xs={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Description:'}&nbsp;
            </Typography>
            <Typography className={classes.description} variant="body1">
              {description}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>
        {/* Data Row 4: Response  */}
        <Grid item container xs={12} >
          <StyledDivForTypographySegments>
            <Typography className={classes.fieldLabel} variant="body1">
              {'Response:'}&nbsp;
            </Typography>
            <Typography className={classes.description} variant="body1">
              {response}
            </Typography>
          </StyledDivForTypographySegments>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default UserFeedbackListViewItem;