import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Box,
  BoxProps,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Tooltip,
  Typography,
  TypographyProps
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Description as DocumentIcon,
  Link as LinkIcon,
  NearMe as NavigationIcon,
} from '@mui/icons-material';
import { IDocumentLink } from '../../../../dataObjects/models/digitalMedia/DocumentLink';
import { listViewItemStyles } from '../../../styles';
import { MenuItemStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumDocumentLinkType } from '../../../../dataObjects/enums';
import { IDocumentLinkViewModel } from '../../../../dataObjects/viewModels/documentLinkViewModel';
import { Channel } from '../../../../dataObjects/models/channels/Channel';


// a styled Popover Menu (used for popping up the 'User' menu)
const StyledPopoverUserMenu = styled((props: MenuProps) => (
  <Menu
    elevation={5}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  // transform: 'translate(0, 40px)',  // move the court left by 50% of its width
}));

// a styled menu item in the Popover Menu
const StyledPopoverMenuItem = styled((props: MenuItemProps) => (
  <MenuItem
    {...props}
  />
))(({ theme }) => ({
  '&:focus': {
    //       // '&.Mui-focus': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.light,
  },
  '&:hover': {
    //       // '&.Mui-hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
  },
}));

// styled text for a menu item in the Popover Menu
const StyledMenuItemText = styled((props: TypographyProps) => (
  <Typography
    {...props}
  />
))(({ theme }) => ({
  marginLeft: '1.0rem'
}));

// a styled Box (equivalent to a <div>), representing a background area for controls on the AppHeader
const StyledNavigateToButtonArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  justifyItems: 'flex-start',
  marginTop: theme.spacing(0.50),
}));


// The next line is preserved for update to React 18, once peer dependencies will allow the update
// export interface DocumentLinksListViewItemProps extends PropsWithChildren<unknown> {
export interface DocumentLinksListViewItemProps {
  documentLinkViewModel: IDocumentLinkViewModel;
  onDelete: (documentLink: IDocumentLink) => void;
}

export const DocumentLinksListViewItem: React.FC<DocumentLinksListViewItemProps> = (props: DocumentLinksListViewItemProps) => {
  DocumentLinksListViewItem.displayName = 'DocumentLinks List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render DocumentLinksListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { documentLinkViewModel, onDelete } = props;
  const { documentLink, accessPermissionToAncestorChannel } = documentLinkViewModel;
  const { id, description, documentLinkType, downloadUrl, name } = documentLink;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  // create an icon and a string based on whether this is a stored document or a linked document
  let storedOrLinkedDocString: string = 'unknown';
  let iconToDisplay: React.JSX.Element = <></>;
  if (documentLinkType === enumDocumentLinkType.GoogleCloudStorage) {
    storedOrLinkedDocString = 'Stored';
    iconToDisplay = <><DocumentIcon className={classes.documentIcon} /></>
  } else if (documentLinkType === enumDocumentLinkType.ExternalSource) {
    storedOrLinkedDocString = 'Linked';
    iconToDisplay = <><LinkIcon className={classes.documentIcon} /></>
  }

  // specifies the anchor position for the "Navigate To" popup menu
  const [navigateToButtonAnchorEl, setUserButtonAnchorEl] = React.useState(null);

  // controls whether the "Navigate To" popup menu is open (showing)
  const openNavigateToMenu = Boolean(navigateToButtonAnchorEl);


  function handleNavigateToButtonClicked(event: any) {
    setUserButtonAnchorEl(event.currentTarget);
  };

  const handleCloseNavigateToMenu = () => {
    setUserButtonAnchorEl(null);
  };

  const handlePageNavigationFromPopupMenu = (pageUrl: string) => {
    // navigate to page
    navigate(pageUrl);

    // close the user menu
    handleCloseNavigateToMenu();
  }

  return (
    <>
      <div className={classes.tableRow} key={id}>
        <StyledNavigateToButtonArea>
          <IconButton
            className={`${classes.navigationIconButton}`}
            size="small"
            aria-label="navigate to"
            aria-controls="navigate-to"
            aria-haspopup="true"
            onClick={(evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => handleNavigateToButtonClicked(evt)}
          >
            <Tooltip title={'Navigate to...'} arrow >
              <NavigationIcon fontSize='small' />
            </Tooltip>
          </IconButton>

          {/* Render the Popover User Menu **ONLY** if the navigateToButtonAnchorEl object has been created */}
          {
            navigateToButtonAnchorEl &&
            <StyledPopoverUserMenu
              id="menu-appbar"
              anchorEl={navigateToButtonAnchorEl}
              anchorReference={navigateToButtonAnchorEl && 'anchorEl'}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={openNavigateToMenu}
              onClose={handleCloseNavigateToMenu}
            >
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${Channel.coreChannelIdFromChannelId(documentLinkViewModel.ancestorChannelId)}/topics`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToAncestorChannel}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${documentLinkViewModel.ancestorTopicId}/topicItems`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToAncestorTopic}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
              <StyledPopoverMenuItem selected={false} onClick={() => handlePageNavigationFromPopupMenu(`/${documentLinkViewModel.parentTopicItemId}/digitalMedia`)}>
                <StyledMenuItemText>
                  {MenuItemStringAssets.menuItem_NavigateToParentTopicItem}
                </StyledMenuItemText>
              </StyledPopoverMenuItem>
            </StyledPopoverUserMenu>
          } {/* end of 'navigateToButtonAnchorEl && ...' */}

        </StyledNavigateToButtonArea>

        {/* Data Row 1: Name & Action Buttons. */}
        <Grid item container xs={12} direction="row" >
          {/* Name */}
          <Grid item container xs={9} sm={10} >
            <a
              href={downloadUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip
                // Tooltip for the name of the Document (with prefix to indicate whether it's a stored or linked document)
                title={`(${storedOrLinkedDocString}) ${name}`}
                arrow
              >
                <span>
                  {/* DocumentLink: Couldn't get proper behavior from <CardHeader>, so will just display a name via <Typography> */}
                  <Typography noWrap variant='body1' align='center'>
                    {iconToDisplay}
                    {name}
                  </Typography>
                </span>
              </Tooltip>
            </a>
          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={3} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} justifyContent='center' >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    aria-label="edit"
                    disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                    onClick={() => id && navigate(`/documentLink/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} justifyContent='center' >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!accessPermissionToAncestorChannel.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    aria-label="delete"
                    disabled={!accessPermissionToAncestorChannel.hasEditPermission}
                    onClick={() => documentLink && onDelete(documentLink)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Description */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default DocumentLinksListViewItem;