import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, LinearProgress, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  VideoLibrary as VideoLibraryIcon,
} from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { typeUniqueId, typeVideoLinkType } from '../../../dataObjects/types';
import { enumDigitalMediaDisplayEnvironment, enumFileUploadType, enumVideoLinkType, enumVideoLinkTypeConvert } from '../../../dataObjects/enums';
import { ISaveVideoLinkRequest } from '../../../dataObjects/models/serviceRequests/videoLink/SaveVideoLinkRequest';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import FileInput from '../../controls/FileInput/FileInput';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { FileForUpload, IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import MediaFileUploadList from '../../listsAndListItems/mediaFiles/MediaFileUploadList';
import { enumAlertType } from '../../enums';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { constructDropZoneFileRejectionsErrorMessage } from '../../utilities/constructDropZoneFileRejectionsErrorMessage';
import { styled } from '@mui/styles';
import { VideoMediaDisplay } from '../../controls/digitalMediaDisplays/VideoMediaDisplay';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { videoLinkFileUploadProgressUpdate } from '../../../uiMiddleware-redux/slices/videoLink/videoLinkSaveStatusSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Container
const StyledBoxForPreviewContainer = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Item
const StyledBoxForPreviewItemArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  // The following combination works in both desktop and mobile browsers, but height is a hardcoded guesstimate and may cause width of image to shrink
  maxWidth: '100%',
  maxHeight: '600px',
  objectFit: 'contain',  // 'contain' & 'scale-down' seem to work similarly
}));

interface IVideoLinkFormValues {
  videoLinkType: typeVideoLinkType;
  videoUrl: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  videoLinkType: yup
    .string(),

  /**
   * @property {string} videoUrl A URL for the VideoLink IFF the type is NOT Video Upload
   */
  videoUrl: yup
    .string(), // needs to be required if a Video site is selected from the VideoLinkType dropdown

  /**
   * @property {string} description A description for the VideoLink.
   */
  description: yup
    .string()
    .required(ControlsStringAssets.videoLinkDescriptionRequired),
});

export interface IVideoLinkFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {typeUniqueId} userId The id of the current user
   */
  userId: typeUniqueId,

  /**
   * @property {IVideoLink} videoLink The VideoLink details for the form (will have blank properties values if we're creating a new record)
   */
  videoLink: IVideoLink,

  /**
   * @property {boolean} saveRequestInProgress (optional) Whether a save request is in progress (default: false).
   */
  saveRequestInProgress?: boolean,

  /**
   * @property {string} progressMessage (optional) If a save is in progress, display this message to provide progress updates to the user.
   */
  progressMessage?: string,

  /**
   * @property {Array<IFileUploadProgress>} fileUploadProgressCollection (optional) If files are in the process of being uploaded, this array
   *                                        provides the details for the upload progress of each file being uploaded.
   */
  fileUploadProgressCollection?: Array<IFileUploadProgress>,

  /**
   * @property {(saveVideoLinkRequest: ISaveVideoLinkRequest) => void} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (saveVideoLinkRequest: ISaveVideoLinkRequest) => void,
}

const VideoLinkForm: React.FC<IVideoLinkFormProps> = (props: IVideoLinkFormProps) => {
  VideoLinkForm.displayName = 'VideoLink Form';

  const dispatch = useAppDispatch();

  // get required arguments from props
  const { userId, videoLink, progressMessage, onSubmit } = props;

  // set up details for ReactHookForm
  const { control, register, formState, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      videoLinkType: videoLink.videoLinkType,
      videoUrl: videoLink.downloadUrl,
      description: videoLink.description,
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: videoLinkTypeReg } = register("videoLinkType", { required: true });
  const { ref: videoUrlReg, ...videoUrlProps } = register("videoUrl", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for convenience, use a state variable to indicate whether we are working with an existing VideoLink
  const [isExistingVideoLink, setIsExistingVideoLink] = useState<boolean>(false);

  // files selected by the user
  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState<Array<IFileForUpload> | null>(null);

  const [videoLinkTypeKey, setVideoLinkTypeKey] = useState<string>("");

  const [videoUrlCurrentValue, setVideoUrlCurrentValue] = useState<string>(videoLink.downloadUrl);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid } = formState;

  const filesAreSelectedForUpload: boolean = (filesSelectedForUpload !== null) ? filesSelectedForUpload.length > 0 : false;

  // determine whether the form is valid for submission, requiring yup validation -AND- either it's an existing VideoLink for 
  // GoogleCloudStorage -OR- files have been selected
  // -OR-
  // it's an external source and a Video URL has been provided
  const formIsValid: boolean = isValid && (((videoLinkTypeKey === enumVideoLinkType.GoogleCloudStorage) && (isExistingVideoLink || filesAreSelectedForUpload))
    || ((videoLinkTypeKey === enumVideoLinkType.ExternalSource) && (videoUrlCurrentValue.trim().length > 0)));

  const [videoLinkTypesForDropdown, setVideoLinkTypesForDropdown] = useState<Array<React.JSX.Element>>([]);

  console.log(`In VideoLinkForm. videoLink.videoLinkType ${videoLink.videoLinkType}; videoLinkTypeKey: ${videoLinkTypeKey}`);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of VideoLinkType values from the enumVideoLinkType enumerator that can be used to populate MenuItem components for the VideoLinkType <Select> component
    let videoLinkTypeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.videoLinkTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      videoLinkTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setVideoLinkTypesForDropdown(videoLinkTypeMenuItems);
  }, []);

  // execute whenever videoLink.videoLinkType changes
  useEffect(() => {
    // set the videoLinkTypeKey from the videoLink.videoLinkType value
    const defaultVideoLinkTypeKey: string = (videoLink.videoLinkType === undefined) ? enumVideoLinkType.GoogleCloudStorage : videoLink.videoLinkType;
    setVideoLinkTypeKey(defaultVideoLinkTypeKey);

  }, [videoLink.videoLinkType]);


  // console.log(`formIsValid: ${formIsValid}; filesSelectedForUpload is ${filesSelectedForUpload === null ? 'null' : 'non-null'}`);
  if (filesSelectedForUpload !== null) {
    // console.log(`filesSelectedForUpload.length: ${filesSelectedForUpload.length}`);
  }

  // if uploading a file, the current progress
  const [fileUploadProgress, setFileUploadProgress] = useState<IFileUploadProgress | undefined>(undefined);

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // for capturing the current value of the Description field
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(videoLink.description);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // if this is an existing videoLink (videoLink.downloadUrl is non-blank), execute logic based on the videoLink.baseStoragePath
  useEffect(() => {
    if (videoLink.downloadUrl && videoLink.baseStoragePath) {
      // set convenience state variable indicating that we're working with an existing VideoLink
      setIsExistingVideoLink(true);
    } else {
      // set convenience state variable indicating that we're NOT working with an existing VideoLink
      setIsExistingVideoLink(false);
    }
  }, [videoLink]);


  function handleVideoUrlCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setVideoUrlCurrentValue(event.target.value);
  }


  function handleDescriptionCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }

  function handleFilesSelectedForUpload<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void {
    // // console.log(acceptedFiles);

    // // console.log(`videoLinkForm.handleFileDrop. Ready to setFilesSelectedForUpload to ${acceptedFiles}`);

    let filesForUpload: Array<IFileForUpload> = new Array<IFileForUpload>();

    // capture any files accepted by the file input
    acceptedFiles.forEach(file => {
      filesForUpload.push(new FileForUpload(file, enumFileUploadType.VideoFiles));

      // clear any alerts
      dispatch(alertInfoChange(null));
    });

    setFilesSelectedForUpload(filesForUpload);

    // if any files were rejected, notify the user with an alert
    if (fileRejections.length > 0) {
      const errorMessage = constructDropZoneFileRejectionsErrorMessage(fileRejections);

      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      const alertInfo: IAlertInfo = new AlertInfo(true, enumAlertType.Error, errorMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  function onFileUploadProgressUpdate(latestFileUploadProgress: IFileUploadProgress): void {
    // alert('Updating the file upload progress');
    setFileUploadProgress(latestFileUploadProgress);

    dispatch(videoLinkFileUploadProgressUpdate(latestFileUploadProgress));
  }

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: any) => {

    setSaveInProgress(true);

    // fill in data from the VideoLink object passed in
    videoLink.description = data.description;
    videoLink.videoLinkType = enumVideoLinkTypeConvert.fromString(videoLinkTypeKey);

    // if the video link is for an external source...
    if (videoLinkTypeKey === enumVideoLinkType.ExternalSource) {
      // set the downloadUrl
      videoLink.downloadUrl = data.videoUrl;
    }

    // prepare a request for saving, starting with just the videoLink
    let saveVideoLinkRequest: ISaveVideoLinkRequest = {
      videoLink: videoLink
    };

    // if this is a new object being saved, along with a file upload...
    if (!videoLink.downloadUrl) {

      // if there are files to be uploaded, proceed
      if (filesSelectedForUpload !== null && filesSelectedForUpload.length > 0) {
        // // set flag indicating that a file upload is in progress
        // setFileUploading(true);

        // prepare the details of the FileUploadRequest
        // for now, only upload the first file in the list
        const fileUploadRequest: IFileUploadRequest = {
          userId: userId,
          fileToUpload: filesSelectedForUpload[0].file,
          fileClass: filesSelectedForUpload[0].fileClass,
          fileUniqueId: filesSelectedForUpload[0].id,
          uploadProgressCallback: onFileUploadProgressUpdate
        };

        // add the FileUploadRequest to the save request object
        saveVideoLinkRequest.fileUploadRequest = fileUploadRequest;

      }
    }

    // call the onSubmit handler passed in, supplying the save request
    await onSubmit(saveVideoLinkRequest);
  }

  const handleDeleteFileForUpload: (fileForUpload: IFileForUpload) => void = (fileForUpload: IFileForUpload) => {
    if (filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      // ...copy the filesSelectedForUpload array to a local array variable, and then remove the specified item from the new array
      let revisedFilesSelectedForUpload: Array<IFileForUpload> = [...filesSelectedForUpload];
      revisedFilesSelectedForUpload.splice(revisedFilesSelectedForUpload.findIndex(fileForUploadElement => fileForUploadElement.id === fileForUpload.id), 1);

      // now, set the revised array into state
      setFilesSelectedForUpload(revisedFilesSelectedForUpload);
    }
  }

  const fileUploadCompletePercent: string = (fileUploadProgress && fileUploadProgress.percentComplete) ?
    fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
    '0';


  const [urlForFileSelectedForUpload, setUrlForFileSelectedForUpload] = useState<string | undefined>(undefined);
  // const [urlWithTimeOffsetForFileSelectedForUpload, setUrlWithTimeOffsetForFileSelectedForUpload] = useState<string | undefined>(undefined);

  // Anytime there's a change in values that pertain to whether there's a file selected for upload, establish the URL (or undefined) for a file upload
  useEffect(() => {
    let newUrlForFileSelectedForUpload: string | undefined = undefined;

    // if this is a new VideoLink (isn't an existing VideoLink) -AND- there's a file selected for upload, obtain a URL for the selected file to be uploaded
    // (Note: If it's either an existing Video Link or there's no file selected for upload, the Url string will be set as undefined.)
    if (!isExistingVideoLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      newUrlForFileSelectedForUpload = URL.createObjectURL(filesSelectedForUpload[0].file);

      setUrlForFileSelectedForUpload(newUrlForFileSelectedForUpload);
    } else {
      setUrlForFileSelectedForUpload(undefined);
    }

  }, [isExistingVideoLink, filesSelectedForUpload, setUrlForFileSelectedForUpload])

  // memoize the rendering of the video object for upload so that it won't flicker each time this form component is re-rendered (such as when file is being uploaded)
  const videoObjectForUploadOperation = useMemo(() => (
    <>
      {
        (urlForFileSelectedForUpload !== undefined) ?
          <>
            <StyledBoxForPreviewContainer>
              <StyledBoxForPreviewItemArea>
                <ReactPlayer
                  playsinline
                  url={urlForFileSelectedForUpload}
                  controls
                />
              </StyledBoxForPreviewItemArea>
            </StyledBoxForPreviewContainer>
          </>
          :
          <>
          </>
      }
    </>

  ), [urlForFileSelectedForUpload]);

  // memoize the rendering of the video for existing VideoLink so that it won't flicker each time this form component is re-rendered (during update operation)
  const videoObjectForExistingLinkUpdate = useMemo(() => (
    <>
      {
        (isExistingVideoLink) ?
          <StyledBoxForPreviewContainer>
            <StyledBoxForPreviewItemArea>
              <VideoMediaDisplay videoLink={videoLink} displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView} />
            </StyledBoxForPreviewItemArea>
          </StyledBoxForPreviewContainer>
          :
          <>
          </>
      }
    </>
  ), [isExistingVideoLink, videoLink.downloadUrl]);


  // prepare specs for accepted filetypes that will be passed on to the FileInput component
  const acceptedFileTypes: Accept = { 'video/*': ['video/mp4', 'video/ogg', 'video/quicktime', 'video/x-mpegURL', 'video/MP2T', 'video/x-msvideo', 'video/3gpp', 'video/webm'] };

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={progressMessage}
        finiteActionInProgressPercentComplete={fileUploadProgress && fileUploadProgress.percentComplete}
        finiteActionInProgressLabel={fileUploadProgress && MessagesStringAssets.file_Uploading}
        formIsValid={formIsValid}
      >

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(videoLinkTypesForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={videoLinkTypeReg}
            label="Video Type"
            margin='normal'
            value={videoLinkTypeKey}
            onChange={e => setVideoLinkTypeKey(e.target.value)}
          >
            {videoLinkTypesForDropdown}
          </StyledTextFieldForSelectControl>
        }

        {/* if the selected Video Link Type is "Upload" (GoogleCloudStorage) -AND-
            if we're not editing an existing videoLink and no files have been selected for upload,  */}
        {(videoLinkTypeKey === enumVideoLinkType.GoogleCloudStorage) && !isExistingVideoLink && !filesAreSelectedForUpload &&
          <FileInput
            control={control}
            name="fileInput"
            // acceptedFileTypes="video/*"
            // The React Player supports a set of video types, so specify the types that it's believed the player accepts
            // acceptedFileTypes="video/mp4, video/ogg, video/quicktime, video/x-mpegURL, video/MP2T, video/x-msvideo, video/3gpp, video/webm"
            acceptedFileTypes={acceptedFileTypes}
            allowMultipleFiles={false}
            onFilesSelectedForUpload={handleFilesSelectedForUpload}
            maxFileSize={262144000} // Max video file size is 250MB
          >
          </FileInput>
        }

        {/* if the currently selected Video Type is file upload (GoogleCloudStorage), we'll have one type of rendering for the 
            video display and any potential file upload activity */}
        {
          videoLinkTypeKey === enumVideoLinkType.GoogleCloudStorage &&

          /* if we're not editing an existing videoLink and files have been selected for upload */
          (!isExistingVideoLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) &&
          <>
            {
              videoObjectForUploadOperation
            }

            <MediaFileUploadList
              filesForUpload={filesSelectedForUpload}
              mediaIcon={<VideoLibraryIcon />}
              onDelete={handleDeleteFileForUpload}
            />

            {
              fileUploadProgress &&
              <>
                <Box display='flex' flexDirection='column' >
                  <Box display="flex" alignItems="center">
                    <Box
                      width="100%"
                      mr={1}>
                      <LinearProgress
                        variant="determinate"
                        value={fileUploadProgress.percentComplete}
                      />
                    </Box>
                    <Box minWidth={35}>
                      <Typography
                        variant="body2"
                        color="primary">
                        {/* {`${Math.round(fileUploadProgress.percentComplete)}%`} */}
                        {`${fileUploadCompletePercent}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <Typography
                      variant="caption"
                      color="primary">
                      {`Uploading video...`}
                    </Typography>
                  </Box>
                </Box>
              </>
            }

          </>
        }

        {/* if the currently selected Video Type is file upload (GoogleCloudStorage) -AND- we're editing 
            an existing VideoLink, display the video object for the existing video... */}
        {
          videoLinkTypeKey === enumVideoLinkType.GoogleCloudStorage && isExistingVideoLink &&
          videoObjectForExistingLinkUpdate
        }

        {/* if the selected Video Link Type is NOT "Upload" (GoogleCloudStorage), show the Video URL text box */}
        {(videoLinkTypeKey !== enumVideoLinkType.GoogleCloudStorage) &&
          <>
            <TextField
              inputRef={videoUrlReg}
              {...videoUrlProps}
              label="Video URL *"
              margin='normal'
              fullWidth
              onChange={handleVideoUrlCurrentValueChanged}
              error={!!errors.videoUrl}
              helperText={errors?.videoUrl?.message}
            />

            <StyledBoxForPreviewContainer>
              <StyledBoxForPreviewItemArea>
                <VideoMediaDisplay videoLink={{ ...videoLink, downloadUrl: videoUrlCurrentValue, description: descriptionCurrentValue, videoLinkType: videoLinkTypeKey === enumVideoLinkType.GoogleCloudStorage ? enumVideoLinkType.GoogleCloudStorage : enumVideoLinkType.ExternalSource }}
                  displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView}
                />

              </StyledBoxForPreviewItemArea>
            </StyledBoxForPreviewContainer>
          </>
        }

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          autoFocus
          label={ControlsStringAssets.topicDescriptionLabel}
          margin='normal'
          fullWidth
          onChange={handleDescriptionCurrentValueChanged}
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default VideoLinkForm;