import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, BoxProps, LinearProgress, MenuItem, TextField, TextFieldProps, Typography } from '@mui/material';
import {
  Image as ImageIcon,
} from '@mui/icons-material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { typeUniqueId } from '../../../dataObjects/types';
import { enumImageFileSize, enumFileUploadType, enumImageLinkType, enumImageLinkTypeConvert, enumDigitalMediaDisplayEnvironment } from '../../../dataObjects/enums';
import { getDownloadUrlsForImageSizes } from '../../../firebaseServices/cloudStorageServices/cloudStorageFilenameUtilities';
import { ISaveImageLinkRequest } from '../../../dataObjects/models/serviceRequests/imageLink/SaveImageLinkRequest';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import FileInput from '../../controls/FileInput/FileInput';
import { Accept, DropEvent, FileRejection } from 'react-dropzone';
import { FileForUpload, IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import MediaFileUploadList from '../../listsAndListItems/mediaFiles/MediaFileUploadList';
import { constructDropZoneFileRejectionsErrorMessage } from '../../utilities/constructDropZoneFileRejectionsErrorMessage';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { styled } from '@mui/styles';
import { ImageMediaDisplay } from '../../controls/digitalMediaDisplays/ImageMediaDisplay';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { imageLinkFileUploadProgressUpdate } from '../../../uiMiddleware-redux/slices/imageLink/imageLinkSaveStatusSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props: TextFieldProps) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Container
const StyledBoxForPreviewContainer = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Item
const StyledBoxForPreviewItemArea = styled((props: BoxProps) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
}));


interface IImageLinkFormValues {
  imageLinkType: string;
  imageUrl: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  imageLinkType: yup
    .string(),
  // .required(),

  /**
   * @property {string} imageUrl A URL for the ImageLink IFF the type is NOT Image Upload
   */
  imageUrl: yup
    .string(), // needs to be required if a Image site is selected from the ImageLinkType dropdown
  // .required(),

  /**
   * @property {string} description A description for the ImageLink.
   */
  description: yup
    .string()
    .required(ControlsStringAssets.imageLinkDescriptionRequired),
});


export interface IImageLinkFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {typeUniqueId} userId The id of the current user
   */
  userId: typeUniqueId,

  /**
   * @property {IImageLink} imageLink The ImageLink details for the form (will have blank properties values if we're creating a new record)
   */
  imageLink: IImageLink,

  /**
   * @property {boolean} saveRequestInProgress (optional) Whether a save request is in progress (default: false).
   */
  saveRequestInProgress?: boolean,

  /**
   * @property {string} progressMessage (optional) If a save is in progress, display this message to provide progress updates to the user.
   */
  progressMessage?: string,

  /**
   * @property {Array<IFileUploadProgress>} fileUploadProgressCollection (optional) If files are in the process of being uploaded, this array
   *                                        provides the details for the upload progress of each file being uploaded.
   */
  fileUploadProgressCollection?: Array<IFileUploadProgress>,

  /**
   * @property {(saveImageLinkRequest: ISaveImageLinkRequest) => void} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (saveImageLinkRequest: ISaveImageLinkRequest) => void,
}

const ImageLinkForm: React.FC<IImageLinkFormProps> = (props: IImageLinkFormProps) => {
  ImageLinkForm.displayName = 'ImageLink Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  // get required arguments from props
  const { userId, imageLink, progressMessage, onSubmit } = props;

  // set up details for ReactHookForm
  // const { control, register, formState, formState: { errors }, handleSubmit } = useForm<IImageLinkFormValues>({
  const { control, register, formState, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      imageLinkType: imageLink.imageLinkType,
      imageUrl: imageLink.downloadUrl,
      description: imageLink.description
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: imageLinkTypeReg } = register("imageLinkType", { required: true });
  const { ref: imageUrlReg, ...imageUrlProps } = register("imageUrl", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for convenience, use a state variable to indicate whether we are working with an existing ImageLink
  const [isExistingImageLink, setIsExistingImageLink] = useState<boolean>(false);

  // files selected by the user
  const [filesSelectedForUpload, setFilesSelectedForUpload] = useState<Array<IFileForUpload> | null>(null);

  const [imageLinkTypeKey, setImageLinkTypeKey] = useState<string>("");

  const [imageUrlCurrentValue, setImageUrlCurrentValue] = useState<string>(imageLink.downloadUrl);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid } = formState;

  const filesAreSelectedForUpload: boolean = (filesSelectedForUpload !== null) ? filesSelectedForUpload.length > 0 : false;

  // determine whether the form is valid for submission, requiring yup validation -AND- either it's an existing ImageLink for 
  // GoogleCloudStorage -OR- files have been selected
  // -OR-
  // it's an external source and a Image URL has been provided
  const formIsValid: boolean = isValid && (((imageLinkTypeKey === enumImageLinkType.GoogleCloudStorage) && (isExistingImageLink || filesAreSelectedForUpload))
    || ((imageLinkTypeKey === enumImageLinkType.ExternalSource) && (imageUrlCurrentValue.trim().length > 0)));

  const [imageLinkTypesForDropdown, setImageLinkTypesForDropdown] = useState<Array<React.JSX.Element>>([]);

  displayConsoleLogs && console.log(`In ImageLinkForm. imageLink.imageLinkType ${imageLink.imageLinkType}; imageLinkTypeKey: ${imageLinkTypeKey}`);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of ImageLinkType values from the enumImageLinkType enumerator that can be used to populate MenuItem components for the ImageLinkType <Select> component
    let imageLinkTypeMenuItems: Array<React.JSX.Element> = [];
    KeyValuePairsStringAssets.imageLinkTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      imageLinkTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setImageLinkTypesForDropdown(imageLinkTypeMenuItems);
  }, []);

  // execute whenever imageLink.imageLinkType changes
  useEffect(() => {
    // set the imageLinkTypeKey from the imageLink.imageLinkType value
    const defaultImageLinkTypeKey: string = (imageLink.imageLinkType === undefined) ? enumImageLinkType.GoogleCloudStorage : imageLink.imageLinkType;
    setImageLinkTypeKey(defaultImageLinkTypeKey);

  }, [imageLink.imageLinkType]);

  // displayConsoleLogs && console.log(`formIsValid: ${formIsValid}; filesSelectedForUpload is ${filesSelectedForUpload === null ? 'null' : 'non-null'}`);
  if (filesSelectedForUpload !== null) {
    // displayConsoleLogs && console.log(`filesSelectedForUpload.length: ${filesSelectedForUpload.length}`);
  }

  // for an existing imageLink, the Map collection of imageSizes and their downloadUrls for the different possible sizes of images
  const [imageUrls, setImageUrls] = useState<Map<string, string> | undefined>(undefined);

  // if uploading a file, the current progress
  const [fileUploadProgress, setFileUploadProgress] = useState<IFileUploadProgress | undefined>(undefined);

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // for capturing the current value of the Description field so that it can be displayed for the 'alt' attribute of the image
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(imageLink.description);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // method to get & set the download Urls for the image
  const setDownloadUrlsForImageStoragePath: (baseStoragePath: string) => void = useCallback(
    (baseStoragePath: string): void => {
      const imageSizes: Array<enumImageFileSize> = [enumImageFileSize.Thumbnail, enumImageFileSize.Small, enumImageFileSize.Medium, enumImageFileSize.Large];
      // const downLoadUrls: Map<string, string> | undefined = undefined;
      getDownloadUrlsForImageSizes(imageLink.baseStoragePath, imageSizes).then((downloadUrlsForImage) => {
        setImageUrls(downloadUrlsForImage);
      }).catch((error) => {
        throw error;
      });
    },
    [imageLink.baseStoragePath]
  );


  // if this is an existing imageLink (imageLink.downloadUrl is non-blank), execute logic based on the imageLink.baseStoragePath
  useEffect(() => {
    if (imageLink.downloadUrl && imageLink.baseStoragePath) {
      // get the downloadUrls for all different file sizes
      setDownloadUrlsForImageStoragePath(imageLink.baseStoragePath);

      // set convenience state variable indicating that we're working with an existing ImageLink
      setIsExistingImageLink(true);
    } else {
      // set convenience state variable indicating that we're NOT working with an existing ImageLink
      setIsExistingImageLink(false);
    }
  }, [imageLink, setDownloadUrlsForImageStoragePath]);


  function handleImageUrlCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setImageUrlCurrentValue(event.target.value);
  }

  function handleFilesSelectedForUpload<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent): void {
    // // displayConsoleLogs && console.log(acceptedFiles);

    // // displayConsoleLogs && console.log(`imageLinkForm.handleFileDrop. Ready to setFilesSelectedForUpload to ${acceptedFiles}`);

    let filesForUpload: Array<IFileForUpload> = new Array<IFileForUpload>();

    acceptedFiles.forEach(file => {
      filesForUpload.push(new FileForUpload(file, enumFileUploadType.ImageFiles));

      // clear any alerts
      dispatch(alertInfoChange(null));
    });

    setFilesSelectedForUpload(filesForUpload);

    // if any files were rejected, notify the user with an alert
    if (fileRejections.length > 0) {
      const errorMessage = constructDropZoneFileRejectionsErrorMessage(fileRejections);

      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      const alertInfo: IAlertInfo = new AlertInfo(true, enumAlertType.Error, errorMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  function onFileUploadProgressUpdate(latestFileUploadProgress: IFileUploadProgress): void {
    // alert('Updating the file upload progress');
    setFileUploadProgress(latestFileUploadProgress);

    dispatch(imageLinkFileUploadProgressUpdate(latestFileUploadProgress));
  }


  // handles a save/submit request from the form
  // const handleSaveSubmit = async (data: IImageLinkFormValues) => {
  const handleSaveSubmit = async (data: any) => {

    setSaveInProgress(true);

    // fill in name & description of the ImageLink object passed in
    imageLink.description = data.description;
    imageLink.imageLinkType = enumImageLinkTypeConvert.fromString(imageLinkTypeKey);

    // if the image link is for an external source...
    if (imageLinkTypeKey === enumImageLinkType.ExternalSource) {
      // set the downloadUrl
      imageLink.downloadUrl = data.imageUrl;
    }

    // prepare a request for saving, starting with just the imageLink
    let saveImageLinkRequest: ISaveImageLinkRequest = {
      imageLink: imageLink
    };

    // if this is a new object being saved, along with a file upload...
    if (!imageLink.downloadUrl) {

      // if there are files to be uploaded, proceed
      if (filesSelectedForUpload !== null && filesSelectedForUpload.length > 0) {
        // // set flag indicating that a file upload is in progress
        // setFileUploading(true);

        // prepare the details of the FileUploadRequest
        // for now, only upload the first file in the list
        const fileUploadRequest: IFileUploadRequest = {
          userId: userId,
          fileToUpload: filesSelectedForUpload[0].file,
          fileClass: filesSelectedForUpload[0].fileClass,
          fileUniqueId: filesSelectedForUpload[0].id,
          uploadProgressCallback: onFileUploadProgressUpdate
        };

        // add the FileUploadRequest to the save request object
        saveImageLinkRequest.fileUploadRequest = fileUploadRequest;

      }
    }

    // call the onSubmit handler passed in, supplying the save request
    await onSubmit(saveImageLinkRequest);
  }

  const handleDeleteFileForUpload: (fileForUpload: IFileForUpload) => void = (fileForUpload: IFileForUpload) => {
    if (filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      // ...copy the filesSelectedForUpload array to a local array variable, and then remove the specified item from the new array
      let revisedFilesSelectedForUpload: Array<IFileForUpload> = [...filesSelectedForUpload];
      revisedFilesSelectedForUpload.splice(revisedFilesSelectedForUpload.findIndex(fileForUploadElement => fileForUploadElement.id === fileForUpload.id), 1);

      // now, set the revised array into state
      setFilesSelectedForUpload(revisedFilesSelectedForUpload);
    }
  }

  const fileUploadCompletePercent: string = (fileUploadProgress && fileUploadProgress.percentComplete) ?
    fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) :
    '0';

  const [urlForFileSelectedForUpload, setUrlForFileSelectedForUpload] = useState<string | undefined>(undefined);
  // const [urlWithTimeOffsetForFileSelectedForUpload, setUrlWithTimeOffsetForFileSelectedForUpload] = useState<string | undefined>(undefined);

  // Anytime there's a change in values that pertain to whether there's a file selected for upload, establish the URL (or undefined) for a file upload
  useEffect(() => {
    let newUrlForFileSelectedForUpload: string | undefined = undefined;

    // if this is a new ImageLink (isn't an existing ImageLink) -AND- there's a file selected for upload, obtain a URL for the selected file to be uploaded
    // (Note: If it's either an existing Image Link or there's no file selected for upload, the Url string will be set as undefined.)
    if (!isExistingImageLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) {
      newUrlForFileSelectedForUpload = URL.createObjectURL(filesSelectedForUpload[0].file);

      setUrlForFileSelectedForUpload(newUrlForFileSelectedForUpload);
    } else {
      setUrlForFileSelectedForUpload(undefined);
    }

  }, [isExistingImageLink, filesSelectedForUpload, setUrlForFileSelectedForUpload])

  function handleDescriptionChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }

  // memoize the rendering of the image object for upload so that it won't flicker each time this form component is re-rendered (such as when file is being uploaded)
  const imageObjectForUploadOperation = useMemo(() => (
    <>
      {
        (urlForFileSelectedForUpload !== undefined) ?
          <StyledBoxForPreviewContainer>
            <StyledBoxForPreviewItemArea>
              <img
                style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'contain' }}  // 'contain' & 'scale-down' seem to work similarly
                src={urlForFileSelectedForUpload}
                alt="Preview"
              />
            </StyledBoxForPreviewItemArea>
          </StyledBoxForPreviewContainer>
          :
          <>
          </>
      }
    </>

  ), [urlForFileSelectedForUpload]);

  // memoize the rendering of the image for existing ImageLink so that it won't flicker each time this form component is re-rendered (during update operation)
  const imageObjectForExistingLinkUpdate = useMemo(() => (
    <>
      {
        (isExistingImageLink && imageUrls) ?
          <>
            <StyledBoxForPreviewContainer>
              <StyledBoxForPreviewItemArea>
                <ImageMediaDisplay
                  imageLink={imageLink}
                  displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView}
                  displayImageSize={enumImageFileSize.Medium}
                  displayImageSizeWhenImageClicked={enumImageFileSize.Large}
                />
              </StyledBoxForPreviewItemArea>
            </StyledBoxForPreviewContainer>
          </>
          :
          <>
          </>
      }
    </>

  ), [imageLink, isExistingImageLink, imageUrls]);

  // prepare specs for accepted filetypes that will be passed on to the FileInput component
  const acceptedFileTypes: Accept = {
    'image/avif': [],
    'image/gif': [],
    'image/jpeg': [],
    'image/png': [],
    'image/tiff': [],
    'image/webp': []
  };

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={progressMessage}
        finiteActionInProgressPercentComplete={fileUploadProgress && fileUploadProgress.percentComplete}
        finiteActionInProgressLabel={fileUploadProgress && MessagesStringAssets.file_Uploading}
        formIsValid={formIsValid}
      >

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(imageLinkTypesForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={imageLinkTypeReg}
            label="Image Type"
            margin='normal'
            value={imageLinkTypeKey}
            onChange={e => setImageLinkTypeKey(e.target.value)}
          >
            {imageLinkTypesForDropdown}
          </StyledTextFieldForSelectControl>
        }

        {/* if the selected link type is "GoogleCloudStorage" -AND- we're not editing an existing imageLink -AND-
           no files have been selected for upload,  */}
        {(imageLinkTypeKey === enumImageLinkType.GoogleCloudStorage) && !isExistingImageLink && !filesAreSelectedForUpload &&
          <FileInput
            control={control}
            name="fileInput"
            // acceptedFileTypes="image/*"
            // The "Resize Images" firebase extension only supports jpg/jpeg and png file types at this time, so we'll limit to those types
            // acceptedFileTypes="image/jpeg,image/jpg,image/png"
            acceptedFileTypes={acceptedFileTypes}
            allowMultipleFiles={false}
            onFilesSelectedForUpload={handleFilesSelectedForUpload}
          >
          </FileInput>
        }

        {/* if we're not editing an existing imageLink and files have been selected for upload */}
        {
          (!isExistingImageLink && filesSelectedForUpload && filesSelectedForUpload.length > 0) &&
          <>
            {
              imageObjectForUploadOperation
            }

            <MediaFileUploadList
              filesForUpload={filesSelectedForUpload}
              mediaIcon={<ImageIcon />}
              onDelete={handleDeleteFileForUpload}
            />

            {
              fileUploadProgress &&
              <>
                <Box display='flex' flexDirection='column' >
                  <Box display="flex" alignItems="center">
                    <Box
                      width="100%"
                      mr={1}>
                      <LinearProgress
                        variant="determinate"
                        value={fileUploadProgress.percentComplete}
                      />
                    </Box>
                    <Box minWidth={35}>
                      <Typography
                        variant="body2"
                        color="primary">
                        {/* {`${Math.round(fileUploadProgress.percentComplete)}%`} */}
                        {`${fileUploadCompletePercent}%`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' justifyContent='center'>
                    <Typography
                      variant="caption"
                      color="primary">
                      {`Uploading image...`}
                    </Typography>
                  </Box>
                </Box>
              </>
            }

          </>
        }

        {/* if the currently selected Image Type is file upload (GoogleCloudStorage) -AND- we're editing 
            an existing ImageLink, display the image object for the existing image... */}
        {imageLinkTypeKey === enumImageLinkType.GoogleCloudStorage && isExistingImageLink && imageUrls &&
          imageObjectForExistingLinkUpdate
        }

        {/* if the selected Image Link Type is NOT "Upload" (GoogleCloudStorage), show the Image URL text box */}
        {(imageLinkTypeKey !== enumImageLinkType.GoogleCloudStorage) &&
          <>
            <TextField
              inputRef={imageUrlReg}
              {...imageUrlProps}
              label="Image URL *"
              margin='normal'
              fullWidth
              onChange={handleImageUrlCurrentValueChanged}
              error={!!errors.imageUrl}
              helperText={errors?.imageUrl?.message}
            />

            <StyledBoxForPreviewContainer>
              <StyledBoxForPreviewItemArea>
                {/* <img
                  style={{ maxWidth: '100%', maxHeight: '600px', objectFit: 'contain' }}  // 'contain' & 'scale-down' seem to work similarly
                  src={imageUrlCurrentValue}
                  alt={descriptionCurrentValue}
                /> */}
                <ImageMediaDisplay
                  imageLink={{ ...imageLink, downloadUrl: imageUrlCurrentValue, description: descriptionCurrentValue, imageLinkType: imageLinkTypeKey === enumImageLinkType.GoogleCloudStorage ? enumImageLinkType.GoogleCloudStorage : enumImageLinkType.ExternalSource }}
                  displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView}
                  displayImageSize={enumImageFileSize.Medium}
                  displayImageSizeWhenImageClicked={enumImageFileSize.Large}
                />
              </StyledBoxForPreviewItemArea>
            </StyledBoxForPreviewContainer>
          </>
        }

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          autoFocus
          label={ControlsStringAssets.topicDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          // we provide a defined onChange handler to capture the description so it can be used to display in the 'alt' attribute of the image
          onChange={handleDescriptionChanged}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default ImageLinkForm;