import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import { IUserFeedback } from '../../../../dataObjects/models/userFeedback';
import UserFeedbackListViewItem from './UserFeedbackListViewItem';
import { IUser } from '../../../../dataObjects/models/users/User';
import { enumSharingPermission } from '../../../../dataObjects/enums';


export interface IUserFeedbackListViewProps extends PropsWithChildren<unknown> {
  userFeedback: Array<IUserFeedback>;
  currentUser: IUser;
  onDelete: (userFeedback: IUserFeedback) => void;
}

export const UserFeedbackListView: React.FC<IUserFeedbackListViewProps> = (props: IUserFeedbackListViewProps) => {
  UserFeedbackListView.displayName = 'UserFeedback List View';

  // eslint-disable-next-line no-console
  // console.info(`Render UserFeedbackListView`);

  const classes: Record<string, string> = listViewStyles();

  const { userFeedback, currentUser, onDelete } = props;

  const userPermission: enumSharingPermission = currentUser.sa ? enumSharingPermission.Admin : enumSharingPermission.None;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='feedbackDetailsLabel' item xs={12} md={10} >
            <Typography variant='h6'>
              Feeback Details
            </Typography>
          </Grid>
          <Grid item container md={2} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          currentUser &&
          userFeedback.map((userFeedback, idx) => (
            <UserFeedbackListViewItem
              key={userFeedback.id}
              userFeedback={userFeedback}
              userPermission={userPermission}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default UserFeedbackListView;
