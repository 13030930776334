import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import { mediaFileUploadListStyles } from '../../styles';
import MediaFileUploadListItem from './MediaFileUploadListItem';


export interface IMediaFileUploadListProps extends PropsWithChildren<unknown> {
  filesForUpload: Array<IFileForUpload>;
  mediaIcon: React.JSX.Element;
  onDelete: (fileForUpload: IFileForUpload) => void;
}

export const MediaFileUploadList: React.FC<IMediaFileUploadListProps> = (props: IMediaFileUploadListProps) => {
  MediaFileUploadList.displayName = 'Media File Upload List';

  // eslint-disable-next-line no-console
  // console.info(`Render MediaFileUploadList`);

  const classes: Record<string, string> = mediaFileUploadListStyles();

  const { filesForUpload, mediaIcon, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and one data row per item: 
          - Header Row
          - Data Row: Icon, Name, Size & Delete Button
      */}
      <Grid container className={classes.root} direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='icon' item xs={1} >
            {/* Don't display a header for the icon column */}
          </Grid>
          <Grid key='name' item container className={classes.name} xs={8} sm={8} >
            <Typography variant='body1'>
              Name
            </Typography>
          </Grid>
          <Grid key='size' item container className={classes.size} xs={3} sm={2} justifyContent='center' >
            <Typography variant='body1'>
              Size
            </Typography>
          </Grid>
          {/* Only show the "Delete" label if the screen size is Small or greater */}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} xs={1} >
              {/* <Typography variant='body1'>
                Delete
              </Typography> */}
            </Grid>
          </Box>
        </Grid>

        {
          filesForUpload.map((fileForUpload, idx) => (
            <MediaFileUploadListItem
              key={fileForUpload.id}
              fileForUpload={fileForUpload}
              mediaIcon={mediaIcon}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default MediaFileUploadList;
